export default {
    namespaced: true,
    state: () => ({
        rows: [],
        statuses: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach((key) => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        async getOrderInWorkData(store, filters) {
            return new Promise((resolve, reject) => {
                WV.ApiMyspace.getOrderInWorkData(filters)
                    .then((response) => {
                        store.commit("set", ["rows", response.data]);
                        resolve();
                    })
                    .catch((err) => reject(err.message));
            });
        },
        async getOrdersStatus(store, payload) {
            return new Promise((resolve, reject) => {
                WV.ApiMyspace.getOrdersStatus(payload)
                    .then((response) => {
                        store.commit("set", ["statuses", response.data]);
                        resolve();
                    })
                    .catch((err) => reject(err.message));
            });
        },
        clearRowsData(store) {
            store.commit("set", ["rows", []]);
        },
        clearStatuses(store) {
            store.commit("set", ["statuses", []]);
        },
    },
    getters: {
        getRows(state) {
            return state.rows;
        },
        getStatuses(state) {
            return state.statuses;
        },
    },
};
