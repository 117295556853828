export default {
    namespaced: true,
    state: () => ({
        groups: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach((key) => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        async getGroups(store) {
            return new Promise((resolve, reject) => {
                WV.ApiMyspace.getOperatorGroups()
                    .then((response) => {
                        store.commit("set", ["groups", response.data]);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err.message);
                    });
            });
        },
    },
    getters: {
        getOperatorGroupOptions(state) {
            return state.groups.map((group) => ({
                id: group.group_id,
                name: group.group_name,
            }));
        },
    },
};
