import { isStageMode } from "@/helpers";

export class BaseApi {
    /**
     * @constructor
     */
    constructor() {
        this.baseUrl = "https://webvork.ru/api/v1/";

        if (process.env.NODE_ENV === "production") {
            this.baseUrl = isStageMode()
                ? "https://webvork.ru/api/v1/"
                : "https://webvork.com/api/v1/";
        }

        this.config = {};
    }

    /**
     * Prepare request headers
     */
    prepareRequest() {
        this.config.headers = {
            "content-Type": "application/json",
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${WV.store.getters["auth/getToken"]}`,
        };
        axios.defaults.withCredentials = true;
    }

    /**
     * GET request
     * @method get
     * @param {string} endpoint - URL
     */
    get(endpoint) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios
                .get(this.baseUrl + endpoint, this.config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * POST request
     * @method post
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    post(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios
                .post(this.baseUrl + endpoint, payload, this.config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * PUT request
     * @method put
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    put(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios
                .put(this.baseUrl + endpoint, payload, this.config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * Delete request
     * @method delete
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    delete(endpoint, payload) {
        this.prepareRequest();
        this.config.data = payload;
        return new Promise((resolve, reject) => {
            axios
                .delete(this.baseUrl + endpoint, this.config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    requestRejected(error) {
        if (typeof error.response.status !== "undefined") {
            if (error.response.status === 401) {
                console.log("API Webvork logged out");
                console.log(error.response.data.message);
                WV.store.dispatch("auth/logout").then(() => {
                    WV.router.push("/");
                });
            }
            if (error.response.status === 500) {
                WV.toast.error(error.response.data.message, 10000);
            }
        }
    }
}

export default class Api extends BaseApi {
    constructor() {
        super();
    }

    postLogin(name, password) {
        return this.post("login", { name: name, password: password });
    }

    getOperatorHoursReport(dateFrom, dateTo) {
        return this.get(`reports/operators-hours-plan-fact?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    }

    getTokenBySession() {
        return this.get(`auth/get-token-by-session`);
    }

    getGoods() {
        return this.get(`goods`);
    }

    getCountries() {
        return this.get(`countries`);
    }
}
