<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width+'px'"
        :height="height+'px'">
        <use
            :class="addclass"
            :xlink:href="getXlinkHref">
        </use>
    </svg>
</template>

<script>
    export default {
        name: 'SvgUse',
        props: {
            width: {
                type: Number,
                default: 24
            },
            height: {
                type: Number,
                default: 24
            },
            id: {
                type: String,
                default: 'whatsappLogoColor'
            },
            addclass: {
                type: String,
                default: ''
            },
        },
        computed: {
            getXlinkHref() {
                return '/images/icons.svg?dsfsdfdsf#' + this.id
            }
        }
    };
</script>

<style scoped lang="scss">

</style>
