export function isStageMode() {
    return (
        document.location.hostname.split(".")[document.location.hostname.split(".").length - 1] ===
        "ru"
    );
}

export function deepClone(obj) {
    if (obj === null || typeof obj !== "object") return obj;

    if (obj instanceof Date) return new Date(obj);

    if (Array.isArray(obj)) {
        return obj.map((item) => deepClone(item));
    }

    const clone = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            clone[key] = deepClone(obj[key]);
        }
    }

    return clone;
}

export function dateToUTC(date) {
    const newDate = new Date(date);
    let utcDate = new Date();

    utcDate.setFullYear(newDate.getUTCFullYear());
    utcDate.setMonth(newDate.getUTCMonth());
    utcDate.setDate(newDate.getUTCDate());
    utcDate.setHours(newDate.getUTCHours());
    utcDate.setMinutes(newDate.getUTCMinutes());
    utcDate.setSeconds(newDate.getUTCSeconds());

    return utcDate;
}

export function disabledBodyScroll() {
    document.body.classList.add("overflow-hidden");
}

export function enabledBodyScroll() {
    document.body.classList.remove("overflow-hidden");
}

export const getCurrentDomainUrl = (path = "") => {
    if (process.env.NODE_ENV === "production") {
        return (isStageMode() ? "https://webvork.ru" : "https://webvork.com") + path;
    }

    return "https://webvork.ru" + path;
};
