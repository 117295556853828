<template>
    <div class="formSelect" :class="{ open: open }">
        <div v-if="label" class="formSelect__label">
            {{ label }}
            <div v-if="tooltip" :data-tippy-content="tooltip" class="formSelect__tooltip">
                <SvgUse :width="14" :height="14" id="help"></SvgUse>
            </div>
        </div>

        <div class="formSelect__wrapper">
            <div class="custom-select" :tabindex="tabindex" @blur="open = false">
                <div class="selected" :class="{ open: open }" @click="open = !open">
                    <span v-if="loading" class="selected_loading">Loading...</span>

                    <template v-else-if="selected">
                        <div v-if="withIcons" class="formSelect__option__icon">
                            <SvgUse :width="18" :height="18" :id="selectedOption.icon"></SvgUse>
                        </div>
                        <div v-if="withImages" class="formSelect__option__image">
                            <img :src="selectedOption.image" alt="" />
                        </div>
                        {{ selectedOptionName }}
                    </template>

                    <div v-else class="formSelect__placeholder">
                        {{ placeholder }}
                    </div>

                    <div v-if="loading" class="formSelect__loading_wrapper">
                        <Loading class="formSelect__loading" />
                    </div>

                    <div v-else class="formSelect__caret">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30px"
                            height="50"
                            viewBox="0 0 24 24"
                        >
                            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                        </svg>
                    </div>
                </div>
                <div class="items formSelect__options" :class="{ selectHide: !open }">
                    <div
                        v-if="showResetOption && !hiddenResetOption"
                        class="formSelect__option formSelect__option__reset"
                        :key="-1"
                        @click="setOption(false)"
                    >
                        Reset
                    </div>
                    <div
                        v-if="hiddenResetOption"
                        class="formSelect__option formSelect__option__no-option"
                    >
                        No options
                    </div>
                    <div
                        class="formSelect__option"
                        v-for="(option, i) of options"
                        :key="i"
                        @click="setOption(option)"
                    >
                        <div v-if="withIcons" class="formSelect__option__icon">
                            <SvgUse :width="18" :height="18" :id="option.icon"></SvgUse>
                        </div>
                        <div v-if="withImages" class="formSelect__option__image">
                            <img :src="option.image" alt="" />
                        </div>
                        {{ option.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="formSelect__notice"></div>
    </div>
</template>

<script>
export default {
    name: "FormSelect",
    props: {
        label: {
            type: [String, Boolean],
            default: "Label",
        },
        tooltip: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        options: {
            type: Array,
            required: true,
            default: [],
        },
        selected: {
            required: true,
        },
        value: {
            required: false,
            default: false,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        showResetOption: {
            type: Boolean,
            required: false,
            default: true,
        },
        withIcons: {
            type: Boolean,
            required: false,
            default: false,
        },
        withImages: {
            type: Boolean,
            required: false,
            default: false,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            open: false,
        };
    },
    emits: ["update:selected", "changed"],
    computed: {
        selectedOption() {
            return collect(this.options).where("id", this.selected).first();
        },
        selectedOptionName() {
            return this.selectedOption.name;
        },
        hiddenResetOption() {
            return !this.options.length;
        },
    },
    methods: {
        setOption(option) {
            this.open = false;
            this.$emit("update:selected", option ? option.id : false);
            this.$emit("changed");
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
@import "../scss/utils/mixins.scss";
@import "../scss/utils/variables.scss";

.formSelect {
    --select-multi-height: 50px;

    width: 100%;
    height: 50px;
    position: relative;
    z-index: 98;
    &.open {
        z-index: 99;
    }

    &__loading {
        max-width: calc(var(--select-multi-height) * 0.5);
        padding: 0px !important;
        margin: 0px !important;

        &_wrapper {
            width: 2rem;
            height: 2rem;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            right: 9px;
            transform: translateY(-50%);
            z-index: 99;
        }
    }

    &__label {
        position: absolute;
        z-index: 99;
        bottom: calc(100% - 10px);
        left: 5px;
        padding: 0 5px;
        border-radius: 5px;
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        height: 20px;
        letter-spacing: 0.5px;
        color: $color-text-muted;
    }
    &__tooltip {
        position: relative;
        margin-left: 7px;
        height: 14px;
        width: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__placeholder {
        color: $color-day-gray;
    }
    &__wrapper {
        height: 50px;
    }
    &__notice {
        min-height: 30px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        font-family: $font-secondary;
        letter-spacing: 0.02em;
    }
    &__options {
        color: $color-day-black;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        border: 0;
        //padding-bottom: 10px;
        position: absolute;
        background-color: $color-day-white;
        left: 0;
        right: 0;
        z-index: 1;
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        max-height: 385px;
        overflow-y: auto;
        @extend .noScrollbar;
    }
    &__option {
        color: $color-day-dark-gray;
        padding-left: 20px;
        cursor: pointer;
        user-select: none;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(.formSelect__option__no-option):hover {
            background-color: $color-day-table;
        }
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-left: -5px;
            margin-right: 15px;
            border-radius: 50%;
            overflow: hidden;
            //border:1px solid $color-day-bg;
        }
        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-left: -10px;
            margin-right: 5px;
            overflow: hidden;
        }
        &__reset {
            color: $color-day-gray;
        }
        &__no-option {
            cursor: default;
        }
    }
    &__caret {
        position: absolute;
        height: 48px;
        width: 40px;
        right: 0;
        top: 0;
    }
}

.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 50px;
    line-height: 50px;
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    .selected {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: $color-day-white;
        border-radius: 3px;
        border: 1px solid $color-day-line;
        color: $color-day-black;
        padding-left: 20px;
        cursor: pointer;
        user-select: none;
        &.open {
            border: 1px solid $color-day-line;
            border-radius: 6px 6px 0px 0px;
        }

        &_loading {
            color: $color-text-muted;
            font-size: 12px;
        }
    }
}

.selectHide {
    display: none;
}
</style>
