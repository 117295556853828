<template>
    <div class="formSelectMulti" v-click-outside="close" :class="{ open: show }">
        <label v-if="label" class="formSelectMulti__label">
            {{ label }}
        </label>

        <div
            class="form-select"
            :tabindex="tabindex"
            :class="{ 'form-select-borderless': borderless }"
            @click="toggle"
        >
            <div class="multiple_items" v-if="multiple">
                <span v-if="loading" class="multiple_item_loading">Loading...</span>

                <span
                    class="multiple_item"
                    v-else-if="selected.length"
                    v-for="(selectedItem, index) in selected"
                >
                    {{
                        getOptionById(selectedItem?.id)?.name ||
                        getOptionById(selectedItem?.id)?.title ||
                        "Undefined"
                    }}

                    <div @click.prevent="removeFromMultiple(index)" class="delete_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                            />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>
                </span>

                <span v-else class="formSelectMulti__placeholder" v-if="!selected.length">
                    {{ placeholder }}
                </span>
            </div>

            <div class="formSelectMulti__placeholder" v-if="!multiple">
                <span class="formSelectMulti__selected" v-if="selected.length">{{
                    selected[0].name || selected[0].title
                }}</span>
                <span v-if="!selected.length">{{ placeholder }}</span>
            </div>

            <div v-if="loading" class="formSelectMulti__loading_wrapper">
                <Loading class="formSelectMulti__loading" />
            </div>

            <span v-else class="dropdown-caret">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="50"
                    viewBox="0 0 24 24"
                >
                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
            </span>
        </div>

        <ul class="form-select-dropdown">
            <div v-if="!hiddenSearchAndReset && showSearch" class="form-select-dropdown__search">
                <input autocomplete="nope" type="text" v-model="searchQuery" placeholder="Search" />
            </div>
            <li
                v-if="!hiddenSearchAndReset && showResetOption"
                @click="clear"
                class="dropdown-item formSelectMulti__reset"
            >
                Reset
            </li>
            <li v-if="hiddenSearchAndReset" class="dropdown-item formSelectMulti__no-options">
                No options
            </li>
            <template v-for="option in optionsFiltered">
                <li v-if="optionVisible(option)" @click="select(option)" class="dropdown-item">
                    {{ option.name }}
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: "FormSelectMulti",
    props: {
        label: {
            type: String,
            default: "",
        },
        selected: {
            type: Array,
            reguired: true,
        },
        options: {},
        except: {},
        placeholder: {
            type: String,
            default: "",
        },
        danger: {},
        borderless: {
            type: Boolean,
            default: false,
        },
        showResetOption: {
            type: Boolean,
            required: false,
            default: true,
        },
        showSearch: {
            type: Boolean,
            required: false,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        nested: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ["update:selected", "changed"],
    data() {
        return {
            show: false,
            searchQuery: "",
        };
    },
    computed: {
        exceptedIds() {
            let exceptedIds = [];
            return exceptedIds;
        },
        optionsFiltered() {
            return this.options.filter((option) => {
                let pass = true;
                if (option.name && this.searchQuery) {
                    pass = option.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1;
                }
                return pass;
            });
        },
        hiddenSearchAndReset() {
            return !this.options.length;
        },
    },
    methods: {
        onBlur() {
            alert("blur!");
        },
        toggle() {
            if (this.show) {
                this.show = false;
            } else {
                this.show = true;
                this.focusInput();
                this.$el.getElementsByClassName("form-select-dropdown")[0].scrollTop = 0;
            }
        },
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        clear() {
            this.show = false;
            this.$emit("update:selected", []);
            this.$emit("changed");
        },
        select(option) {
            this.show = false;
            this.searchQuery = "";

            if (!this.multiple) {
                this.$emit("update:selected", [option]);
                this.$emit("changed");
            } else {
                this.$emit("update:selected", [...this.selected.map((i) => ({ ...i })), option]);
                this.$emit("changed");
            }
        },
        getOptionById(id) {
            return this.options.find((option) => {
                return option.id === id;
            });
        },
        removeFromMultiple(index) {
            this.selected.splice(index, 1);
            this.show = false;
        },
        focusInput() {
            if (this.showSearch) {
                this.$el.querySelector("input")?.focus();
            }
        },
        getChildsOfDropdownItem(item) {
            if (this.nested) {
                return this.options.filter((option) => {
                    return parseInt(option.parent) === parseInt(item.id);
                });
            } else {
                return this.options;
            }
        },
        optionVisible(option) {
            let passFilter = true;

            if (option.name && this.searchQuery) {
                passFilter =
                    option.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1;
            }

            if (passFilter && this.except) {
                passFilter = this.exceptedIds.indexOf(option.id) !== -1;
            }

            if (passFilter && this.selected) {
                passFilter = !collect(this.selected).contains("id", option.id);
            }

            let childOptions = this.getChildsOfDropdownItem(option);

            if (!passFilter && childOptions.length && this.searchQuery) {
                passFilter =
                    childOptions.filter((childOption) => {
                        return (
                            childOption.name
                                .toLowerCase()
                                .indexOf(this.searchQuery.toLowerCase()) !== -1
                        );
                    }).length > 0;
            }

            return passFilter;
        },
    },
    beforeMount() {
        if (this.value === 0 || this.value) {
            if (this.multiple) {
                this.selected = [];
                this.value.forEach((item) => {
                    this.selected.push(this.getOptionById(item));
                });
            } else {
                this.selected =
                    typeof this.value == "object"
                        ? this.getOptionById(this.value.id)
                        : this.getOptionById(this.value);
            }
        }
    },
};
</script>

<style lang="scss">
@import "./../scss/utils/mixins.scss";
@import "./../scss/utils/variables.scss";

$formSelectHeight: 37px;
$formElementBorderColor: #f4f4f4;
$color-text-muted: #999;
$dropdown-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
$formElementBgActive: #f9fafc;
$brand-danger: #bf5329;

.formSelectMulti {
    --select-multi-height: 50px;

    width: 100%;
    position: relative;
    height: var(--select-multi-height);

    &__loading {
        max-width: calc(var(--select-multi-height) * 0.5);
        padding: 0px !important;
        margin: 0px !important;

        &_wrapper {
            width: 2rem;
            height: 2rem;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            right: 9px;
            transform: translateY(-50%);
            z-index: 99;
        }
    }

    &__label {
        position: absolute;
        z-index: 99;
        bottom: calc(100% - 10px);
        left: 5px;
        padding: 0 5px;
        border-radius: 5px;
        background: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 13px;
        line-height: 120%;
        height: 20px;
        letter-spacing: 0.5px;
        color: $color-text-muted;
    }
    &__placeholder {
        font-size: 14px;
        color: $color-day-gray;
        white-space: nowrap;
    }
    &__selected {
        color: $color-text-default;
    }
    &__reset {
        color: $color-day-gray !important;
    }
}

.form-select {
    width: 100%;
    display: block;
    min-height: $form-control-height - 2px;
    padding: 0 0 0 20px;
    line-height: $form-control-height - 2px;
    border: 1px solid $color-day-line;
    position: relative;
    background: white;
    font-size: 15px;
    border-radius: 3px;
    z-index: 20;
    font-family: $font-secondary;
    transition: all 0.12s linear;
    .multiple_items {
        height: $form-control-height - 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        flex-wrap: nowrap;
        margin-right: 50px;
        margin-left: -10px;
        @extend .noScrollbar;
    }
    .multiple_item {
        font-size: 15px;
        background: $color-day-bg;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        line-height: 30px;
        padding: 0 5px 0 7px;
        border-radius: 3px;
        margin: 0 10px 0 0;
        white-space: nowrap;
        .delete_icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 3px;
            padding-top: 0;
            opacity: 0.5;
            svg {
                height: 14px;
                width: 14px;
            }
            &:hover {
                opacity: 1;
            }
        }

        &_loading {
            color: $color-text-muted;
            font-size: 12px;
        }
    }
    .dropdown-caret {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 20px;
        color: $color-day-dark-gray2;
        transition: all 0.12s linear;
    }

    &:hover {
        border: 1px solid $color-day-line;
        cursor: pointer;
    }
}

.form-select-borderless {
    border-color: transparent;
    .dropdown-caret {
        color: transparent;
    }
    &:hover {
        .dropdown-caret {
            color: $color-text-default;
        }
    }
}

.form-select-dropdown {
    position: absolute;
    top: 100%;
    background: white;
    border: 0;
    width: 100%;
    overflow: auto;
    z-index: 999;
    max-height: 0;
    transition: all 50ms ease;
    padding: 0;
    box-shadow: $dropdown-shadow;
    .dropdown-item {
        padding: 0 10px;
        line-height: 30px;
        font-size: 14px;
        font-family: $font-secondary;
        color: $color-text-default;
        white-space: nowrap;
        &:not(.dropdown-item.formSelectMulti__no-options):hover {
            cursor: pointer;
            background: $formElementBgActive;
            color: $color-text-default;
        }
        &-disabled {
            color: $color-text-muted;
            cursor: not-allowed;
            &:hover {
                cursor: not-allowed;
                color: $color-text-muted;
                background: transparent;
            }
        }
    }
    &__search {
        padding-bottom: 5px;
        input {
            width: 100%;
            font-family: $font-secondary;
            font-size: 14px;
            line-height: 30px;
            border: 0;
            border-bottom: 1px solid $color-day-line;
            padding: 5px 10px;
            &,
            &:active,
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.open {
    .form-select {
        border: 1px solid $color-day-line;
        background: $formElementBgActive;

        .dropdown-caret {
            color: $color-text-default;
        }
    }

    .form-select-dropdown {
        max-height: 230px;
        list-style: none;
    }
}

.form-error {
    .form-label {
        color: $brand-danger;
    }
    .form-input {
        border: 1px solid $brand-danger;
    }
}
</style>
