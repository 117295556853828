export default {
    namespaced: true,
    state: () => ({
        countries: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach((key) => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        getCountries(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getCountries()
                    .then((response) => {
                        store.commit("set", ["countries", response.data]);
                        resolve();
                    })
                    .catch((err) => reject(err.message));
            });
        },
    },
    getters: {
        getCountryByCode: (state) => (country_code) => {
            return collect(state.goods).where("code", country_code).first();
        },
        getCountryOptions(state) {
            return state.countries.map((country) => {
                return {
                    id: country.code,
                    name: country.name,
                };
            });
        },
    },
};
