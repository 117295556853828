export default {
    namespaced: true,
    state: () => ({
        operators: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach((key) => {
                state[key] = items[key];
            });
        },
    },
    actions: {
        getOperatorsByGroup(store, groupIds) {
            return new Promise((resolve, reject) => {
                WV.ApiMyspace.getOperatorsByGroup(groupIds)
                    .then((response) => {
                        store.commit("set", ["operators", response]);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err.message);
                    });
            });
        },
        cleanOperators(store) {
            store.commit("clean", { operators: [] });
        },
    },
    getters: {
        getOperatorOptions(state) {
            return state.operators.map((operator) => {
                return {
                    id: operator.id,
                    name: `${operator.first_name} ${operator.last_name}`,
                    groups: operator.group_ids,
                };
            });
        },
    },
};
