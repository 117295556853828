import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */'../views/Auth/Login.vue'),
        meta: {
            isInternal: false,
            title: 'Login'
        }
    },
    {
        path: '/crossales',
        name: 'crossales',
        component: () => import(/* webpackChunkName: "operators" */'../views/Crossales/Crossales.vue'),
        meta: {
            isInternal: true,
            title: 'Crossales'
        }
    },
    {
        path: '/operators/worktime-plan-fact',
        name: 'worktime-plan-fact',
        component: () => import(/* webpackChunkName: "operators" */'../views/Operators/WorktimePlanFact/WorktimePlanFact.vue'),
        meta: {
            isInternal: true,
            title: 'Worktime Plan & Fact'
        }
    },
    {
        path: '/operators/activity-report',
        name: 'activity-report',
        component: () => import(/* webpackChunkName: "operators" */'../views/Operators/ActivityReport/ActivityReport.vue'),
        meta: {
            isInternal: true,
            title: "Operators Activity Report"
        }
    },
    {
        path: '/orders-in-work',
        name: 'orders-in-work',
        component: () => import(/* webpackChunkName: "ordersInWork" */'../views/OrdersInWork/OrdersInWork.vue'),
        meta: {
            isInternal: true,
            title: "Orders in Work"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { path: "/" },
        name: '404',
        component: () => import(/* webpackChunkName: "auth" */'../views/Auth/Login.vue'),
        meta: {
            isInternal: false,
            title: 'Error 404'
        }
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
